import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import ProfileCard from "./Components/ProfileCard";

import AccesosTab from "./Components/AccesosTab";
import PasswordTab from "./Components/PasswordTab";
import BillingTab from "./Components/BillingTab";
import { ConfigTab } from "./Components/ConfigTab";
import ApplicationsTab from "./Components/ApplicationsTab";
const Profile = () => {
    const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const suscription = urlParams.get('tab');
  console.log(suscription);
  document.title = "Perfil | Panda Móvil";

  const navTabs = [
    { number: "1", title: "Mis Datos" },
    { number: "2", title: "Mis demás números" },
    { number: "3", title: "Mis suscripciones" },
    // { number: "4", title: "Contraseña" },
    // { number: "5", title: "Configuración" },
  ];

  const [activeTab, setActiveTab] = useState(suscription=='suscripcion'?"3":"1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Perfil</h1>

          <Row>
            <Col xxl={3}>
              <ProfileCard />
            </Col>

            <Col xxl={9}>
              <Card className="">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    {navTabs.map((navTab) => (
                      <NavItem key={navTab.number}>
                        <NavLink
                          className={classnames({
                            active: activeTab === navTab.number,
                          })}
                          onClick={() => {
                            tabChange(navTab.number);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {navTab.title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <AccesosTab />
                    </TabPane>

                    <TabPane tabId="2">
                      <ApplicationsTab />
                    </TabPane>

                    <TabPane tabId="3">
                      <BillingTab />
                    </TabPane>

                    <TabPane tabId="4">
                      <PasswordTab />
                    </TabPane>

                    <TabPane tabId="5">
                      <ConfigTab />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
