import React, { useMemo } from "react";
import { Col, Form, Input, Label, Row, Spinner } from "reactstrap";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { useState } from "react";
import { authProvider } from "../../../Components/Hooks/authProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FeedbackMessage } from '../../../Components/Common/FeedbackMessage';
import { ProductsProvider } from "../../../Components/Hooks/ProductsProvider";

const AccesosTab = () => {
  const { user: userInfo } = useLoggedIn();
  const { updateUser, loadingUpdateUser, changePassword, loadingChangePwd } = authProvider();
  const hasParent = useMemo(() => !!userInfo?.parent_id, [userInfo]);
  const { getRegimenQuery } = ProductsProvider();
  const [selectedType, setSelectedType] = useState(userInfo?.odoo_regimen_tipo || 'fisica');
  const [passwordShow, setPasswordShow] = useState(false);
  const { data: regimes,refetch} = getRegimenQuery(selectedType);


  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      msisdn: userInfo?.msisdn || "",
      name: userInfo?.name || "",
      email: userInfo?.email || "",
      zip: userInfo?.zip || "",
      vat: userInfo?.vat || "",
      l10n_mx_edi_fiscal_regime: userInfo?.l10n_mx_edi_fiscal_regime || "",
      is_independent: userInfo?.is_independent,
      last_name: userInfo?.last_name,
      odoo_razon_social: userInfo?.odoo_razon_social,
      odoo_factura_email: userInfo?.odoo_factura_email,
      odoo_regimen_tipo: userInfo?.odoo_regimen_tipo
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor ingrese su nombre"),
      last_name: Yup.string().required("Por favor ingrese su apellido"),
      email: Yup.string().email().required("Por favor ingrese su correo"),
    }),
    onSubmit: (values) => {
      updateUser(values);
    },
  });

  const passwordForm = useFormik({
    initialValues: {
      password: '',
      current_password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("Por favor ingresa la contraseña actual")
        .min(8, 'La contraseña debe tener al menos 8 caracteres'),
      password: Yup.string().required("Por favor ingresa la nueva contraseña")
        .min(8, 'La contraseña debe tener al menos 8 caracteres'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .min(8, 'La contraseña debe tener al menos 8 caracteres'),
    }),
    onSubmit: (values) => {
      changePassword(values);
    },
  });

  const handleTypeChange = (e) => {
    const type = e.target.value;
    console.log(type);
    setSelectedType(type);
    form.setFieldValue('odoo_regimen_tipo', type);
    refetch();
  };

  return (
    <React.Fragment>
      <Form onSubmit={(e => {
        e.preventDefault();
        form.handleSubmit();
        return false;
      })}>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="firstnameInput" className="form-label">
                Nombre
              </Label>
              <Input
                name="name"
                type="text"
                className="form-control"
                id="firstnameInput"
                placeholder="Ingrese su nombre"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name || ""}
                invalid={!!form.touched.name && !!form.errors.name}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'name'} />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="lastnameInput" className="form-label">
                Apellido
              </Label>
              <Input
                name="last_name"
                type="text"
                className="form-control"
                id="lastnameInput"
                placeholder="Ingrese su apellido"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.last_name || ""}
                invalid={!!form.touched.last_name && !!form.errors.last_name}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'last_name'} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailInput" className="form-label">
                Email
              </Label>
              <Input
                name="email"
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="Ingrese su email"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.email || ""}
                invalid={!!form.touched.email && !!form.errors.email}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'email'} />
            </div>
          </Col>
        </Row>

        <Row className="pt-5">
        <h5>Datos de facturacion</h5>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="cp" className="form-label">
                Codigo Postal
              </Label>
              <Input
                name="zip"
                type="text"
                className="form-control"
                id="cp"
                placeholder="CP"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.zip || ""}
                invalid={!!form.touched.zip && !!form.errors.zip}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'zip'} />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="RFC" className="form-label">
                RFC
              </Label>
              <Input
                name="vat"
                type="text"
                className="form-control"
                id="RFC"
                placeholder="RFC"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.vat || ""}
                invalid={!!form.touched.vat && !!form.errors.vat}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'vat'} />
            </div>
          </Col>
        </Row>

        <Row>
        <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="Regimen_tipo" className="form-label">
                Tipo de persona
              </Label>
              <Input
                type="select"
                name="odoo_regimen_tipo"
                className="form-control"
                id="Regimen_tipo"
                onChange={handleTypeChange}
                onBlur={form.handleBlur}
                value={selectedType}
                invalid={!!form.touched.odoo_regimen_tipo && !!form.errors.odoo_regimen_tipo}
                disabled={hasParent}
              >
                <option value="fisica">Física</option>
                <option value="moral">Moral</option>
              </Input>
              <FeedbackMessage form={form} propName={'odoo_regimen_tipo'} />
            </div>
          </Col>

          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="Regimen" className="form-label">
                Régimen Fiscal
              </Label>
              <Input
                type="select"
                name="l10n_mx_edi_fiscal_regime"
                className="form-control"
                id="Regimen"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.l10n_mx_edi_fiscal_regime || ""}
                invalid={!!form.touched.l10n_mx_edi_fiscal_regime && !!form.errors.l10n_mx_edi_fiscal_regime}
                disabled={hasParent}
                >
                <option value="">Selecciona un régimen</option>
                {(regimes &&
                  Object.entries(regimes).map(([id, name]) => (
                    <option key={id} value={id}>
                      {id} - {name}
                    </option>
                  ))
                )}
              </Input>
              <FeedbackMessage form={form} propName={'l10n_mx_edi_fiscal_regime'} />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
            <Label htmlFor="razonSocialInput" className="form-label">
                Razon Social
              </Label>
              <Input
                name="odoo_razon_social"
                type="text"
                className="form-control"
                id="razonSocialInput"
                placeholder="Ingrese su razon social"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.odoo_razon_social || ""}
                invalid={!!form.touched.odoo_razon_social && !!form.errors.odoo_razon_social}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'odoo_razon_social'} />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailFacturaInput" className="form-label">
                Email para factura
              </Label>
              <Input
                name="odoo_factura_email"
                type="email"
                className="form-control"
                id="emailFacturaInput"
                placeholder="Ingrese su email que estara relacionado a la factura"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.odoo_factura_email || ""}
                invalid={!!form.touched.odoo_factura_email && !!form.errors.odoo_factura_email}
                disabled={hasParent}
              />
              <FeedbackMessage form={form} propName={'odoo_factura_email'} />
            </div>
          </Col>
        </Row>

        { !hasParent &&
          <Row>
            <Col lg={12} className="mt-4 mb-5">
              <div className="hstack gap-2 justify-content-center">
                <button type="submit" className="btn btn-primary" disabled={loadingUpdateUser}>
                  {!loadingUpdateUser && <span>Guardar cambios</span>}
                  {loadingUpdateUser && <Spinner size="sm"></Spinner>}
                </button>
              </div>
            </Col>
          </Row>
        }
      </Form>

      <Form className="mt-5" onSubmit={(e => {
        e.preventDefault();
        passwordForm.handleSubmit();
        return false;
      })}>
        <Row>
          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Contraseña Actual
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                autoComplete="off"
                name="current_password"
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Ingrese contraseña"
                onChange={passwordForm.handleChange}
                onBlur={passwordForm.handleBlur}
                value={passwordForm.values.current_password}
                invalid={!!passwordForm.touched.current_password && !!passwordForm.errors.current_password}
              />
              <FeedbackMessage form={passwordForm} propName={'current_password'} />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="see-current-password"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>

          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Contraseña Nueva
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                autoComplete="off"
                name="password"
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Ingrese contraseña nueva"
                onChange={passwordForm.handleChange}
                onBlur={passwordForm.handleBlur}
                value={passwordForm.values.password}
                invalid={!!passwordForm.touched.password && !!passwordForm.errors.password}
              />
              <FeedbackMessage form={passwordForm} propName={'password'} />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="see-new-password"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Confirmar Contraseña Nueva
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                autoComplete="off"
                name="password_confirmation"
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Confirme contraseña nueva"
                onChange={passwordForm.handleChange}
                onBlur={passwordForm.handleBlur}
                value={passwordForm.values.password_confirmation}
                invalid={!!passwordForm.touched.password_confirmation && !!passwordForm.errors.password_confirmation}
              />
              <FeedbackMessage form={passwordForm} propName={'password_confirmation'} />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="see-new-password-confirm"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="mt-4">
            <div className="hstack gap-2 justify-content-center">
              <button type="submit" className="btn btn-primary" disabled={loadingChangePwd}>
                {!loadingChangePwd && <span>Actualizar contraseña</span>}
                {loadingChangePwd && <Spinner size="sm"></Spinner>}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AccesosTab;
